$(function () {
    const $paymentPlan = $('#paymentplan');
    const $currencySelect = $('#pricing-currency-select');
    const $priceContainers = $('.price-container');

    // Set default currency based on Cloudflare's country code
    function setDefaultCurrency() {
        // Get country code from the data attribute that will be populated by the server
        const countryCode = $('body').data('country-code') || '';
        const isDollarCountry = ['US', 'CA', 'EC'].includes(countryCode);

        // Default to dollar for US/Canada/Ecuador, euro for everyone else
        if (isDollarCountry) {
            $currencySelect.val('dollar');
        }
    }

    function formatCurrency(amount, currencyCode) {
        if (!amount) return '';

        // Remove any existing currency symbols or formatting to get clean number
        const numericValue = parseFloat(amount.toString().replace(/[^\d.,]/g, '').replace(',', '.'));

        if (isNaN(numericValue)) return amount;

        // Get user's locale or default to browser's locale
        const userLocale = navigator.language || navigator.userLanguage || 'en-US';

        // Format the currency with the proper locale and currency code
        const formatter = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });

        return formatter.format(numericValue);
    }

    function updatePrices() {
        // Determine current state
        const isYear = $paymentPlan.hasClass('year');
        const isDollar = $currencySelect.val() === 'dollar';

        // Set period and currency for finding the right data attribute
        const period = isYear ? 'year' : 'month';
        const currency = isDollar ? 'dollar' : 'euro';
        const dataAttr = `data-price-${period}-${currency}`;

        const currencyCode = isDollar ? 'USD' : 'EUR';

        // For each price container, update the value with formatted currency
        $priceContainers.each(function () {
            const $container = $(this);
            const priceValue = $container.attr(dataAttr);

            if (priceValue) {
                const formattedPrice = formatCurrency(priceValue, currencyCode);

                $container.find('.pricing-table-value').html(formattedPrice);
            }
        });

        // Handle mobile accordion prices (which use a different structure)
        $('.accordion-item').each(function () {
            const $item = $(this);
            const accordionPeriodClass = `.price.${period}`;

            $item.find('.price').removeClass('d-flex').addClass('d-none');

            $item.find(accordionPeriodClass).removeClass('d-none').addClass('d-flex');

            // Update the visible price values in accordion
            $item.find(`${accordionPeriodClass} .h4`).each(function () {
                const $priceElement = $(this);
                const rawPrice = $priceElement.text().trim();

                if (rawPrice) {
                    const formattedPrice = formatCurrency(rawPrice, currencyCode);
                    $priceElement.html(formattedPrice);
                }
            });
        });
    }

    // Handle payment plan toggle
    $paymentPlan.on('input', function () {
        const isYear = $(this).hasClass('year');

        $(this).toggleClass('year', !isYear);
        $(this).toggleClass('month', isYear);

        updatePrices();
    });

    // Handle currency change
    $currencySelect.on('change', function () {
        updatePrices();
    });

    // Set default currency and initialize prices
    setDefaultCurrency();
    updatePrices();
});
